html {
  --bonadocsBackground: #151b24;
  --bonadocsText: #7f97b4;
  --bonadocsSecondaryText: #e2e7ef;
  --bonadocsButtonBg: #416bff;
  --bonadocsStatusBg: #334356;
  --bonadocsEditorBackground: #0f141b;
  --bonadocsEditorBorder: #20262e;
  --bonadocsTextWhite: #ffffff;
  --bonadocsTextCritical: #b50037;
  --bonadocsCodeSecondary: #82aaff;
  --bonadocsInfoBg: #01173d;
  --bonadocsCircleBg: #94a3b8;
  --bonadocsSuccessBg: #e6f8ee;
  --bonadocsCode: #292d3e;
  --bonadocsCodePrimary: #d05b32;
  --bonadocsCodeSecondary: #e2e7ef;
  --bonadocsSuccessCircleBg: #015d2a;
  --bonadocsErrorBg: #f8e6e6;
  --bonadocsDeleteColor: #f25081;
  --bonadocsLoginBg: #0f141b;
  --bonadocsStatusBgLight: #b8c8ff;
  --bonadocsYellow: #f6dc39;
  --bonadocsBlue: #4278ed;
  --bonadocsCodeBg: #0d1117;
  --bonadocsNetworkBg: #131921;
  --bonaodocsPlaceholder: #252f3b;
}

.bona__bg {
  background-color: var(--bonadocsEditorBackground);
  color: white;
  font-weight: 700;
}

.bona__pt {
}

.bona__bt {
  border-top: 1px solid var(--bonadocsEditorBorder);
}

.bona__transition {
  transform: rotate(90deg);
}

.ma-0 {
  margin: 0rem !important;
}

.ma-r0 {
  margin-right: 1.3rem;
}
.ma-top {
  margin-top: 2rem;
}
.ma-top-md {
  margin-top: 2.4rem;
}

.ma-bottom {
  margin-bottom: 3.2rem;
}

.ma-bottom-sm {
  margin-bottom: 1.6rem;
}
.pa-1 {
  padding: 1.2rem !important;
}

textarea {
  resize: none;
}

body {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: var(--bonadocsProtocolBackground)
    var(--bonadocsEditorBackground); /* scroll thumb and track */
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--bonadocsBackground) var(--bonadocsEditorBackground);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--bonadocsEditorBorder);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bonadocsText);
  border-radius: 20px;
  // border: 4px solid var(--bonadocsBackground);
}

.bona__active {
  background-color: var(--bonadocsEditorBackground);

  border-radius: 10rem;
}

.bona__btl {
  margin-top: 0.8rem;
  border-radius: none !important;
}

.bona__mat {
  border-top: 1px solid var(--bonadocsEditorBorder);
  border-radius: none !important;
}

.bona__header {
  border-bottom: 1px solid var(--bonadocsEditorBorder);
  border-radius: 0.8rem 0.8rem 0 0 !important;
}

.bona__play__actions {
  border-radius: 0 0 0.8rem 0.8rem !important;
}

.ma-l {
  margin-left: 1.3rem;
}

.ma-l2 {
  margin-left: 2rem;
}

.ma-auto {
  margin-left: auto;
}

// override react-tooltip styles
.react-tooltip {
  border: 1px solid var(--bonadocsEditorBorder) !important;
  background-color: var(--bonadocsEditorBackground) !important;
  color: var(--bonadocsTextWhite) !important;
  font-size: 1.2rem !important;
}

.styles-module_arrow__K0L3T {
  border-bottom: 1px solid var(--bonadocsEditorBorder) !important;
  border-right: 1px solid var(--bonadocsEditorBorder) !important;
  background-color: var(--bonadocsEditorBackground) !important;
}

.bonadocs__circle {
  background-color: var(--bonadocsCircleBg);
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.bonadocs__circle__simulation {
  background-color: var(--bonadocsText);
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.connected__green {
  // background-color: var(--bonadocsSuccessBg) !important;
  span {
    color: var(--bonadocsTextWhite);
  }
}

.white {
  color: var(--bonadocsTextWhite);
}

.error__red {
  background-color: var(--bonadocsErrorBg) !important;
  color: var(--bonadocsDeleteColor);
  span {
    color: var(--bonadocsDeleteColor);
  }
}

.error__red__circle {
  background-color: var(--bonadocsDeleteColor);
}

.connected__green__circle {
  background-image: url("https://res.cloudinary.com/dfkuxnesz/image/upload/v1715228394/Online_fklfri.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
}

.bonadocs__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC)
    repeat scroll transparent\9;
  color: white;
  display: none;
  z-index: 1;
  align-items: center;
}

.bonadocs__overlay__img {
  height: 3rem;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
}

.bona__disabled {
  pointer-events: none;
}

.input-group label {
  font-size: 2.8rem;
}

.ReactModal__Overlay {
  z-index: 100;
}

.demarcation {
  width: 100%;
}

.wid-100 {
  width: 100%;
}

.bona-black {
  color: var(--bonadocsEditorBackground) !important;
}

.bor-main {
  border: 1px solid var(--bonadocsProtocolBorder);
  border-radius: 0.8rem;
}

.demarcation:before,
.demarcation:after {
  content: "";
  height: 0.5px;
  background: linear-gradient(
    to right,
    rgba(32, 38, 46, 0) 0%,
    #20262e 50%,
    rgba(32, 38, 46, 0) 100%
  );

  display: block;

  margin-top: 2.4rem;
}

.demarcation1 {
  content: "";
  // height: 0.5px;
  background: linear-gradient(to right, #20262e, #20262e, #7f97b4);

  border: 1px solid #20262e;

  display: block;

  margin-top: 2.4rem;
}

.demarcation3 {
  color: var(--bonadocsText);
    background-color: var(--bonadocsLoginBg);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-md {
  width: 1.4rem;
  height: 1.4rem;
  align-self: center;
}

.pa-big {
  padding: 0 30.6rem;
}

.pal-md {
  padding-left: 12rem;
}

.bonadocs {
  &__editor {
    &__sidebar {
      width: 15%;
      padding: 1.6rem 2.4rem;
      max-width: 30rem;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1500px) {
        width: 16%;
      }
      @media screen and (max-width: 900px) {
        display: none;
      }
      &__info {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1300px) {
          margin-top: 1rem;
        }
        &__code {
          border: 1px solid #20262e;
          border-radius: 1.6rem;
          padding: 2.4rem;
          margin-top: 2.4rem;
          justify-items: center;
          &__placeholder {
            margin: 20rem 42.1rem 0 49rem;
            max-width: 23rem;
            margin: 0 auto;
            margin-top: 20rem;
            // @media screen and (min-width: 1500px) {
            //   margin: 25rem 50rem 0 55rem;
            //   width: ;
            // }
          }

          &__title {
            text-align: center;
          }
        }
        &__warning {
          display: flex;
          margin: -1.6rem -3.9rem 1.6rem -2.4rem;
          align-items: flex-start;
          background: var(--bonadocsBlue);
          height: 12rem;
          color: var(--bonadocsTextWhite);
          font-size: 1.6rem;
          padding: 0.8rem 1.6rem;
          &__text {
            color: var(--bonadocsTextWhite);
            font-weight: 500;
            line-height: 2.5rem; /* 153.846% */
            letter-spacing: 0.013rem;

            &__cta {
              text-decoration: underline;
              padding-left: 0.3rem;
              cursor: pointer;
            }
          }
        }
      }
      &__logo {
        margin-bottom: 4rem;
        cursor: pointer;
      }
      &__item {
        display: flex;
        color: var(--bonadocsText);
        gap: 1rem;
        font-size: 1.6rem;
        padding: 0.8rem;
        cursor: pointer;
      }
      &__options {
        margin-top: auto;
        margin-bottom: 10rem;
      }
      &__simulation {
        display: flex;
        align-items: center;
        height: min-content;
        gap: 0.8rem;
        font-size: 1.4rem;
        border-radius: 10rem;
        /* max-width: 250px; */
        padding: 1rem;
        position: relative;
        background: linear-gradient(to right, #8748ee, #9d1770);
        padding: 1px;
        margin-bottom: 0.8rem;
        &__inner {
          width: 100% !important;
          gap: 1rem;
          &__active {
            background: linear-gradient(to right, #8748ee, #9d1770);
          }
        }
        &__title {
          color: white;
        }
      }
      &__selector {
        border: 1px solid var(--bonadocsProtocolBorder);
        margin: 2.4rem 0;
        border-radius: 2rem;
        display: flex;
        padding: 0.8rem 1.2rem;
        cursor: pointer;
        &__team {
          &__name {
            color: var(--bonadocsSecondaryText);
            font-size: 1.3rem;
          }
          &__dropdown {
            margin-left: auto;
          }
        }
      }
    }
    &__method {
      display: flex;
      width: 100%;
      &__params {
        font-size: 1.3rem;
        &__item {
          margin-top: 1rem;
        }
        &__name {
          color: var(--bonadocsSecondaryText);
          font-size: 1.3rem;
          margin-top: 0.8rem;
        }
        &__input {
          width: 100%;
        }
        &__button {
          margin-left: auto;
          font-size: 1.3rem;
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}

// .border-gradient {
//   border: 10px solid;
//   border-image-slice: 1;
//   border-width: 1px;
//   border-radius: 10rem;
// }
// .border-gradient-purple {
//   border-image-source: linear-gradient(
//     to top left,
//     #ff75c3,
//     #ffa647,
//     #ffe83f,
//     #9fff5b,
//     #70e2ff,
//     #cd93ff
//   );
//   border-radius: 10rem !important;
// }

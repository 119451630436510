@mixin header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px solid var(--bonadocsEditorBorder);
}

@mixin header__title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--bonadocsSecondaryText);
  line-height: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.bonadocs {
  &__editor {
    &__view {
      &__button {
        align-self: end;
        margin: 1rem 0;
      }
    }
    &__dashboard {
      height: 100vh;
      display: flex;
      flex-direction: column;
      &__playground {
        display: flex;
        background-color: var(--bonadocsEditorBackground);
        border-radius: 0.8rem;
        position: relative;
        height: 82vh;
        flex: 1;
        &__placeholder {
          &--actions {
            padding-top: 25%;
          }
        }
        // overflow-y: auto;
        @media screen and (max-height: 700px) {
          height: 100rem;
        }
        @media screen and (min-height: 950px) {
          height: 86vh;
        }
        @media screen and (min-height: 1200px) {
          height: 89vh;
        }

        &__dropdown {
          border: 1px solid var(--bonadocsEditorBorder);
          background: transparent;
          width: min-content;
          color: var(--bonadocsText);
          padding: 0.8rem 2.4rem 0.8rem 1.6rem;
          border-radius: 10rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: transparent;
          background-image: url("https://res.cloudinary.com/dfkuxnesz/image/upload/v1703342137/Project_Details_Container_zndbzp.svg");
          background-repeat: no-repeat;
          background-position-x: 95%;
          background-position-y: 50%;
          align-self: end;
          margin-top: 0.5rem;
          margin-left: auto;
        }

        &__transaction {
          &__param {
            &__item {
              &__popover {
                &__input {
                  border: 1px solid var(--bonadocsButtonBg);
                  background-color: var(--bonadocsInfoBg);
                  width: 100%;
                }
              }
            }
            &__list {
              padding: 1.6rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-content: space-between;
            }
            &__item {
              width: inherit;
              margin-bottom: 0.8rem;
              &__input {
                width: 100%;
              }

              &__popover {
                z-index: 4;
              }

              &__header {
                display: flex;
                justify-content: space-between;
                h3 {
                  color: var(--bonadocsTextWhite);
                }
              }

              &__description {
                background-color: var(--bonadocsBackground);
                width: 36rem;
                white-space: nowrap;
                padding: 0.8rem;
                border-radius: 0.8rem;
              }
              
            }
          }
        }
        &__package {
          &__list {
            display: flex;
            flex-direction: column;
            width: 18%;
            height: 100%;
            overflow-y: hidden;
            border-right: 1px solid var(--bonadocsEditorBorder);

            &__header {
              @include header;
              &__title {
                @include header__title;
              }
            }
            &__children {
              // padding: 0.4rem 0.8rem 0.4rem 2.4rem;
              &__item {
                padding: 0.4rem 0;
                cursor: pointer;
                font-size: 1.3rem;
                padding: 0.8rem 1.6rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;

                align-items: center;
                svg {
                  width: 1.9rem;
                }
                &__active {
                  background-color: var(--bonadocsBackground);
                }
                &__img {
                  margin-left: auto;
                  width: 1.6rem;
                  height: 1.6rem;
                }
                &__tab {
                  margin-left: 0.4rem;
                }
                &:hover {
                  background-color: var(--bonadocsBackground);
                  border-radius: 0.8rem;
                }
              }
            }
          }
        }
        &__action {
          &__list {
            display: flex;
            flex-direction: column;
            width: 18%;
            height: 100%;
            overflow-y: hidden;
            border-right: 1px solid var(--bonadocsEditorBorder);

            &__header {
              @include header;
              &__title {
                @include header__title;
              }
            }
            &__children {
              // padding: 0.4rem 0.8rem 0.4rem 2.4rem;
              &__item {
                padding: 0.4rem 0;
                cursor: pointer;
                font-size: 1.3rem;
                padding: 0.4rem 0.8rem 0.4rem 2.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                gap: 1rem;
                align-items: center;
                svg {
                  width: 1.9rem;
                }
                &__active {
                  background-color: var(--bonadocsBackground);
                }
                &__img {
                  margin-left: auto;
                }
              }
            }
          }
          &__code {
            width: 45%;
            border-right: 1px solid var(--bonadocsEditorBorder);
            display: flex;
            flex-direction: column;
            &__docs {
              background-color: transparent;
              color: var(--bonadocsText);
              width: 100%;
              height: 100%;
              font-size: 1.4rem;
              line-height: 3rem;
              resize: none;
              overflow-x: hidden;
              padding: 1.6rem;
            }

            &__button {
              width: 100%;
              border-top: 1px solid var(--bonadocsEditorBorder);
              display: flex;
              justify-content: flex-end;
              padding: 0.8rem;
              &__item {
                display: inherit;
                align-items: center;
                gap: 0.5rem;
                img {
                  margin-right: 0.4rem;
                }
              }
            }
            &__view {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
              overflow: auto;
            }
          }
          &__output {
            width: 37%;
            display: flex;
            flex-direction: column;
          }
        }
        &__contract {
          display: flex;
          flex-direction: column;
          width: 18%;
          height: 100%;
          overflow-y: hidden;
          border-right: 1px solid var(--bonadocsEditorBorder);
          &__header {
            @include header;
            &__title {
              @include header__title;
            }
            &__addIcon {
              margin-left: auto;
              cursor: pointer;
              align-self: center;
            }
          }
          &__type {
            color: var(--bonadocsText);
            &__container {
              background-color: var(--bonadocsBackground);
              margin: 0.8rem 0.4rem;
              border-radius: 10rem;
              display: flex;
              padding: 0.4rem;
              &__item {
                border-radius: 10rem;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                width: 50%;
                font-size: 1.4rem;
                display: inline-flex;
                padding: 0.4rem;
                cursor: pointer;
              }
            }
          }
          &__list {
            overflow-y: auto;
          }
        }
        &__method {
          width: 45%;
          border-right: 1px solid var(--bonadocsEditorBorder);
          display: flex;
          flex-direction: column;
          &__header {
            display: flex;
            align-items: center;
            padding: 0.8rem;
            height: 4rem;
            border-bottom: 1px solid var(--bonadocsEditorBorder);
            &__title {
              font-size: 1.4rem;
              font-weight: 600;
              color: var(--bonadocsSecondaryText);
              line-height: 2.4rem;
            }
            &__icon {
              margin-left: auto;
            }
            &__text {
               margin-left: auto;
               color: var(--bonadocsProtocolBlue); 
               cursor: pointer; 
            }
          }
          &__view {
            flex: 1;
            overflow-y: auto;
            display: flex;
            z-index: 1;
            &__simulate {
              margin-top: 1rem;
              div {
                display: flex;
                gap: 0.6rem;
              }
            }
            &__doc {
              display: none;
            }
            &__params {
              padding: 1.6rem;
              display: flex;
              flex-direction: column;
              width: 100%;
            }
            &__markdown {
              background-color: transparent;
              color: var(--bonadocsText);
              width: 100%;
              height: 100%;
              font-size: 1.4rem;
              line-height: 3rem;
              resize: none;
              overflow-x: hidden;
              padding: 1.6rem;
            }
            &__preview {
              color: var(--bonadocsText);
              width: 100%;
              height: 100%;
              font-size: 1.4rem;
              overflow-x: hidden;
              padding: 1.6rem;
              img {
                max-width: 50rem;
              }
              * {
                padding: 0.3rem;
              }
            }
          }
          &__controlbar {
            background-color: var(--bonadocsBackground);
            display: flex;
            padding-top: 0.8rem;
            font-size: 1.3rem;

            &__cloud {
              margin-left: auto;
              margin-right: 1.6rem;
            }

            &__item {
              padding: 1.2rem 4rem 0.4rem 4rem;
            }
          }
          &__view {
            &__status {
              display: flex;
              align-items: end;
              justify-content: space-between;
            }
          }
        }
        &__result {
          width: 37%;
          display: flex;
          flex-direction: column;
          &__docs {
            flex: 1;
            overflow-y: auto;
            min-height: 42%;
            &__title {
              font-size: 2rem;
              font-weight: 700;
              color: var(--bonadocsSecondaryText);
              line-height: 2.4rem;
              padding: 0.8rem;
            }
            &__content {
              padding: 0.8rem;
              line-height: 2.4rem;
              font-size: 1.4rem;
              height: 100%;
              &__textarea {
                height: 100%;
                width: 100%;
              }
            }
          }
          &__header {
            display: flex;
            align-items: center;
            padding: 0.8rem;
            height: 4rem;
            border-bottom: 1px solid var(--bonadocsEditorBorder);
            &__title {
              font-size: 1.4rem;
              font-weight: 600;
              color: var(--bonadocsSecondaryText);
              line-height: 2.4rem;
            }
            &__icon {
              margin-left: auto;
            }
          }
        }
        &__placeholder {
          display: flex;
          justify-content: center;
          font-size: 1.6rem;
          width: 25%;
          height: 15%;
          margin: 0 auto;
          flex-direction: column;
          img {
            height: 30rem;
          }
          h3 {
            color: var(--bonadocsTextWhite);
            text-align: center;
          }
          span {
            margin-top: 0.5rem;
            text-align: center;
          }
        }
      }
    }
  }
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .margin,
.monaco-editor div {
  // background-color: var(--bonadocsEditorBackground);
  font-family: Menlo, Monaco, "Courier New", monospace;
}

.bonadocs {
  &__button {
    border-radius: 2.4rem;
    padding: 0.4rem 0.8rem;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.4rem;
    &__action {
      background-color: var(--bonadocsButtonBg);
      color: var(--bonadocsEditorBackground);
    }
    &__inertia {
      color: var(--bonadocsText);
      background-color: var(--bonadocsBackground);
    }
    &__critical {
      color: var(--bonadocsTextWhite);
      background-color: var(--bonadocsTextCritical);
    }
    &__default {
      color: var(--bonadocsText);
      background-color: transparent;
      border: 1px solid var(--bonadocsText);
    }
    &:disabled {
      opacity: .2;
    }
  }
}
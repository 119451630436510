.bonadocs {
  &__editor {
    &__dashboard {
      flex: 1;
      padding: 1.6rem;
      &__header {
        background-color: var(--bonadocsEditorBackground);
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        padding: 1.6rem;
        &__connect {
          background: linear-gradient(to right, #8748ee, #9d1770);
          padding: 1px;
          width: -moz-fit-content;
          width: fit-content;
          font-size: 1.4rem;
          border-radius: 10rem;
          margin-left: 1.6rem;
          button {
            padding: .4rem 1.2rem;
          }
        }
        &__status {
          margin-left: 1.6rem;
        }
        &__title {
          font-size: 1.4rem;
          font-weight: 600;
          color: var(--bonadocsSecondaryText);
          line-height: 2.4rem;
        }
        &__view {
          position: absolute;
          z-index: 4;
          &__wrapper {
            display: grid;
            border: 1px solid var(--bonadocsEditorBorder);
            border-radius: 0.8rem;
            margin-top: 1rem;
            padding: 1.2rem;
            width: 19.1rem;
            background-color: var(--bonadocsEditorBackground);
            font-size: 1.3rem;
            cursor: pointer;
            &:hover {
              background-color: var(--bonadocsBackground);
              color: var(--bonadocsTextWhite);
            }
          }
        }
        &__profile {
          display: flex;
          align-items: center;
          color: var(--bonadocsTextWhite);
          margin-left: 1rem;
          font-size: 1.3rem;
          background-color: var(--bonadocsBackground);
          border-radius: 10rem;
          padding: 0.4rem 0.8rem;
          font-weight: 600;
          cursor: pointer;
          &__image {
            width: 3rem;
            height: 3rem;
            border-radius: 10rem;
            margin-right: 0.5rem;
          }
        }
        &__share {
          margin-left: auto;
          display: flex;
          align-items: center;
          padding-left: 1.6rem;
          &__publish {
            margin-right: 1.6rem;
          }
        }
        &__networkDropdown {
          align-self: end;
        }
      }
      &__controlbar {
        display: flex;
        color: var(--bonadocsText);
        margin-top: 1.6rem;
        &__item {
          // background-color: var(--bonadocsEditorBackground);
          // border-radius: 0 0 50% 50% / 10% 10% 0 0;
          width: 15.3rem;
          cursor: pointer;
          font-size: 1.4rem;
          height: 3.2rem;
          padding: 0.4rem 4rem;
          border-top-left-radius: 0.8rem;
          border-top-right-radius: 0.8rem;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          display: inline-flex;
          margin-left: 1.6rem;
          &__rightcurl {
            position: absolute;
            margin-bottom: -1.7rem;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background: transparent;
            box-shadow: -1rem 1rem 0 var(--bonadocsEditorBackground);
            margin-left: 16.5rem;
            z-index: 0;
          }
          &__leftcurl {
            position: absolute;
            margin-bottom: -1.7rem;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background: transparent;
            box-shadow: 1rem 1rem 0 var(--bonadocsEditorBackground);
            margin-left: -16.5rem;
          }
        }
      }
    }
  }
}

@-webkit-keyframes background {
  0% {
    background-image: url("http://sipi.usc.edu/database/preview/aerials/2.1.07.png");
  }
  33% {
    background-image: url("http://sipi.usc.edu/database/preview/aerials/2.1.06.png");
  }
  100% {
    background-image: url("http://sipi.usc.edu/database/preview/aerials/2.1.12.png");
  }
}

.bonadocs {
  &__accordion {
    &__parent {
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      padding: 0.8rem 0.4rem;
      gap: 0.4rem;
      cursor: pointer;
      &__icon {
        transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
        min-width: 2rem;
      }
      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &__tooltip {
          margin-bottom: 8rem;
          position: absolute;
          &__item {
            display: grid;
            border: 1px solid var(--bonadocsEditorBorder);
            border-radius: 0.8rem;
            padding: 1rem;
            width: min-content;
            background-color: var(--bonadocsEditorBackground);
            font-size: 1.6rem;
            white-space: no-wrap;
          }
        }
      }
    }
    &__children {
      // padding: 0.4rem 0.8rem 0.4rem 2.4rem;
      &__item {
        padding: 0.4rem 0;
        cursor: pointer;
        font-size: 1.3rem;
        padding: 0.4rem 0.8rem 0.4rem 2.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &__active {
          background-color: var(--bonadocsBackground);
        }
      }
    }
  }
}
@font-face {
  font-family: "Satoshi-Regular";
  src: url("../../../font/Satoshi-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Menlo";
  src: url("https://res.cloudinary.com/dckecq0n3/raw/upload/v1705714330/bonadocs/menlo.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

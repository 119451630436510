html {
  --bonadocsEditorBackground: #EEF2F5;
  --bonadocsEditorSubBackground: #FFFFFF;
  --bonadocsProtocolPrimaryText: #100A37;
  --bonadocsProtocolSecondaryText: #475569;
  --bonadocsProtocolSecondaryBackground: #0f141b;
  --bonadocsProtocolBorder: #20262e;
  --bonadocsProtocolOverlay: #0f141b;
  --bonadocsProtocolBlue: #055DF5;
}

.bonadocs {
  &__tab {
    padding: 0.8rem 1.6rem;
    background-color: var(--bonadocsBackground);
    height: min-content;
    border-radius: 10rem;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    width: fit-content;
    display: flex;
    align-items: center;
    &__result {
      &__status {
        margin: 1.6rem;
      }
    }
    &__package {
      border-radius: 0.2rem;
      padding: 0rem .4rem;
      font-size: 1rem;
      &__neutral {
        border: 0.5px solid var(--bonadocsEditorBorder);
      }
      &__yellow {
        border: 0.5px solid var(--bonadocsYellow);
      }
      &__blue {
        border: 0.5px solid var(--bonadocsBlue);
      }
    }
  }
}

.bonadocs {
  &__editor {
    
    &__login {
      background-color: var(--bonadocsLoginBg);
      height: 100vh;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 1.6rem;
      background-image: url(https://res.cloudinary.com/dfkuxnesz/image/upload/v1714589362/Background_lnstj8.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      &__inner {
        padding: 0 12rem;
        width: 100%;
        display: grid;

        &__title {
          justify-self: center;
          margin-top: 10.6rem;
          color: var(--bonadocsSecondaryText);
          font-size: 2.4rem;
        }
        &__cta {
          justify-self: center;
          font-size: 2.4rem;
          margin-top: 4rem;
          border: 1px solid var(--bonadocsEditorBorder);
          border-radius: 10rem;
          padding: 2rem;
          display: flex;
          width: 40%;
          justify-content: center;
          gap: 2rem;
          @media screen and (min-width: 2000px) {
            width: 90rem;
          }
          &__button {
            padding: 1.5rem !important;
            width: 50%;
            border-radius: 10rem;
            color: var(--bonadocsSecondaryText) !important;
            display: flex;
            align-items: center;
            justify-content: center;
            &__icon {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}

.absolute {
  // position: absolute;
  align-self: end;
  display: flex;
  align-items: end;
  inset: 0;
  height: 100%;

  display: inline-flex;
  flex-direction: row;
  //  overflow-x: hidden;
  margin-top: -8rem !important;
  width: 100%;
  animation: pulse 3s ease infinite;
  filter: blur(80px);
  filter: opacity(25%);
  // background: radial-gradient(
  //     at 3% 88%,
  //     hsla(179, 100%, 45%, 1) 0px,
  //     transparent 30%
  //   ),
  //   radial-gradient(at 35% 80%, hsla(220, 100%, 37%, 1) 50px, transparent 35%),
  //   radial-gradient(at 25% 94%, hsla(336, 100%, 67%, 1) 0px, transparent 30%),
  //   radial-gradient(at 85% 100%, hsla(352, 100%, 50%, 1) 0px, transparent 40%),
  //   radial-gradient(at 100% 100%, hsla(41, 100%, 50%, 1) 0px, transparent 20%);
}

// @keyframes pulse {
//   0%,
//   100% {
//     transform: scale(0.95);
//     background-color: radial-gradient(
//         at 3% 98%,
//         hsl(219, 7%, 47%) 0px,
//         transparent 50%
//       ),
//       radial-gradient(at 35% 10%, hsl(219, 7%, 47%) 50px, transparent 35%),
//       radial-gradient(at 75% 24%, hsl(219, 7%, 47%) 0px, transparent 30%),
//       radial-gradient(at 80% 100%, hsl(219, 7%, 47%) 0px, transparent 70%),
//       radial-gradient(at 100% 100%, hsl(219, 7%, 47%) 0px, transparent 20%);
//   }
//   50% {
//     transform: scale(1);
//     background-color: radial-gradient(
//         at 3% 98%,
//         hsla(179, 100%, 45%, 1) 0px,
//         transparent 50%
//       ),
//       radial-gradient(at 35% 10%, hsla(220, 100%, 37%, 1) 50px, transparent 35%),
//       radial-gradient(at 75% 24%, hsla(336, 100%, 67%, 1) 0px, transparent 30%),
//       radial-gradient(at 80% 100%, hsla(352, 100%, 50%, 1) 0px, transparent 70%),
//       radial-gradient(at 100% 100%, hsla(41, 100%, 50%, 1) 0px, transparent 20%);
//   }

// }

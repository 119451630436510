.bonadocs__editor__status {
  position: relative;
  display: inline-block;
  width: 2.8rem;
  height: 1.5rem;
}
.bonadocs__editor__status .bonadocs__editor__status__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bonadocs__editor__status__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  box-shadow: 0 0 1px var(--bonadocsButtonBg);
  transition: 0.4s;
}

.bonadocs__editor__status__slider:before {
  position: absolute;
  content: "";
  height: 1.1rem;
  width: 1.1rem;
  left: 3px;
  right: 2px;
  bottom: 1.5px;
  // background-color: var(--bonadocsButtonBg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.bonadocs__editor__status__input:checked
  + .bonadocs__editor__status__slider:before {
  background-color: var(--bonadocsButtonBg);
}

.bonadocs__editor__status__input:checked
  + .bonadocs__editor__status__slider:before {
  background-color: var(--bonadocsButtonBg);
}

.bonadocs__editor__status__input:not(:checked)
  + .bonadocs__editor__status__slider:before {
  background-color: var(--bonadocsEditorBackground);
}

.bonadocs__editor__status__input:checked {
  .bonadocs__editor__status__slider {
    background-color: var(--bonadocsButtonBg);
  }
}

.bonadocs__editor__status__input + .bonadocs__editor__status__slider {
  background-color: var(--bonadocsStatusBgLight);
}

/* .bonadocs__editor__status__input:not(:checked)  + .bonadocs__editor__status__slider {
  background-color: var(--bonadocsButtonBg);
} */

.bonadocs__editor__status__input:focus + .bonadocs__editor__status__slider {
  box-shadow: 0 0 2px var(--bonadocsStatusBg);
}

.bonadocs__editor__status__input:checked
  + .bonadocs__editor__status__slider:before {
  -webkit-transform: translateX(1.1rem);
  -ms-transform: translateX(1.1rem);
  transform: translateX(1.1rem);
}

/* Rounded bonadocs__widget__status__sliders */
.bonadocs__editor__status__slider.bonadocs__editor__status__round {
  border-radius: 34px;
}

.bonadocs__editor__status__slider.bonadocs__editor__status__round:before {
  border-radius: 50%;
}

.bonadocs {
  &__editor {
    &__input {
      border: 1px solid var(--bonadocsEditorBorder);
      padding: 1.2rem;
      color: var(--bonadocsTextWhite);
      margin-top: 0.4rem;
      border-radius: 0.4rem;
      &__textarea {
        height: 10rem;
        resize: none;
      }
      &__radio {
        align-items: center;
        font-size: 1.3rem;
        margin-right: 0.8rem;
        &__text {
          font-size: 1.3rem;
        }
      }
    }
    &__input::placeholder {
      color: var(--bonaodocsPlaceholder);
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: var(--bonadocsText);
    }
    &__param {
      &__item {
        &__popover {
          &__input {
            border: 1px solid var(--bonadocsButtonBg);
            background-color: var(--bonadocsInfoBg);
            width: 100%;
          }
          height: min-content;
          max-height: 50rem;
          overflow-y: scroll;
        }
        &__teams {
          background-color: var(--bonadocsBackground);
          width: 25rem;
          white-space: nowrap;
          padding: 01.6rem;
          cursor: pointer;
          font-size: 1.3rem;
        }
        &__teams:hover {
          background-color: var(--bonadocsEditorBackground);
          h3,
          h4 {
            color: var(--bonadocsTextWhite);
          }
        }
      }
      &__list {
        padding: 1.6rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: space-between;
      }
      &__item {
        width: inherit;
        margin-bottom: 0.8rem;
        &__input {
          width: 100%;
        }

        &__popover {
          z-index: 4;
        }

        &__header {
          display: flex;
          justify-content: space-between;

          h3 {
            color: var(--bonadocsTextWhite);
          }
        }

        &__description {
          background-color: var(--bonadocsBackground);
          width: 36rem;
          white-space: nowrap;
          padding: 0.8rem;
          border-radius: 0.8rem;
        }
      }
      &__select {
        text-decoration: none;
        -webkit-appearance: none;
        border: 1px solid var(--bonadocsEditorBorder);
        padding: 1.2rem;
        color: var(--bonadocsTextWhite);
        background-color: var(--bonadocsSecondaryBg);
        color: var(--bonadocsTextTertiary);
        border-radius: 0.4rem;
        margin-left: auto;
        // font-size: 0.8rem;
        padding: 0.8rem;
        width: 100%;
        /* min-width: 60%; */
        background-color: var(--bonadocsSecondaryBg);
        background-image: url("https://res.cloudinary.com/dfkuxnesz/image/upload/v1704123676/Stepper_uz65vf.svg");
        background-repeat: no-repeat;
        background-size: 20% 65%;
        background-position-x: 108%;
        background-position-y: 50%;
        &__placeholder {
          color: var(--bonaodocsPlaceholder);
        }
      }
    }
  }
}

input[type="radio"] {
  appearance: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-clip: content-box;
  border: 1px solid var(--bonadocsText);
  background-color: transparent;
  padding: 2px;
  margin: 0.4rem 0.4rem -0.4rem 0.2rem;
}

input[type="radio"]:checked {
  background-color: var(--bonadocsBlue);
  padding: 2px;
  border: 2px solid var(--bonadocsBlue);
}

input[type="checkbox"] {
  appearance: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-clip: content-box;
  border: 1px solid var(--bonadocsText);
  background-color: transparent;
  padding: 2px;
  margin: 0.4rem 0.4rem -0.4rem 0.2rem;
}

input[type="checkbox"]:checked {
  background-color: var(--bonadocsBlue);
  padding: 2px;
  border: 2px solid var(--bonadocsBlue);
}

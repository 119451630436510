.modal {
  &__side {
    &__container {
      background-color: var(--bonadocsEditorBackground);
      padding: 2.4rem;
      width: inherit;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      height: max-content;
     
      &__header {
        display: flex;
        width: 100%;
        &__title {
          color: var(--bonadocsSecondaryText);
          font-size: 1.6rem;
          line-height: 2.4rem; /* 150% */
          letter-spacing: 0.016rem;
        }
        &__description {
          font-size: 1.6rem;
        }
      }
      &__input {
        border: none;
        font-size: 1.3rem;
        width: 100%;
      }
      &__search {
        margin-top: 2.8rem;
        padding: 0.8rem;
        &__button {
          margin-left: auto;
        }
        &__open {
          padding: 1.2rem;
          border-radius: 0.8rem;
          box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.3);
          // max-height: 20%;
          overflow: auto;
          display: grid;
          &__cta {
            width: 100%;
            margin-top: auto;
          }
          &__inner {
            overflow-y: auto;
            height: 30rem;
          }
          &__item {
            padding: 1.2rem;
            display: grid;
            display: flex;
            gap: 0.8rem;
            cursor: pointer;
            &:hover {
              background-color: var(--bonadocsBackground);
              border-radius: 0.8rem;
            }
            &__text {
              color: var(--bonadocsTextWhite);
            }
            img {
              width: 2.4rem;
              height: 2.4rem;
            }
            &__input {
              margin-left: auto;
            }
          }
        }
        &__bar {
          display: flex;
          align-items: center;
        }
        &__icon {
          margin: 0 auto;
        }
      }
      &__result {
        margin-top: 0.8rem;
        display: grid;
        overflow-y: auto;
        height: 40rem;
        &__item {
          padding: 1.2rem;
          display: grid;

          &:hover {
            background-color: var(--bonadocsBackground);
            border-radius: 0.8rem;
          }
          &__button {
            justify-self: end;
          }

          &__title {
            display: flex;
            &__info {
              display: flex;
              align-items: center;
              margin-left: auto;
              cursor: pointer;
              h4 {
                color: var(--bonadocsBlue);
                font-size: 1.4rem;
                line-height: 2.4rem;
                margin-left: 0.8rem;
                margin-right: 0.4rem;
                cursor: pointer;
              }
            }
            &__name {
              font-size: 1.4rem;
              font-weight: 700;
              line-height: 2.4rem;
            }
          }
          &__description {
            margin-top: 0.4rem;
            font-size: 1.3rem;
          }
        }
      }

      &__popover {
        position: absolute;
        z-index: 4;
        &__item {
          display: grid;
          border: 1px solid var(--bonadocsEditorBorder);
          border-radius: 0.8rem;
          padding: 1.6rem;
          width: 19.1rem;
          background-color: var(--bonadocsEditorBackground);
          font-size: 1.6rem;
          &__option {
            width: 100%;
            padding: 0.8rem;
            cursor: pointer;
            display: flex;
            &__title:hover {
              color: var(--bonadocsTextWhite);
            }
            &:hover > &__download {
              display: block;
              color: var(--bonadocsTextWhite);
            }

            &:hover {
              background-color: var(--bonadocsBackground);
              color: var(--bonadocsTextWhite);
            }
            &__download {
              margin-left: auto;
              display: none;
            }
          }
        }
      }
    }
    &__close {
      background-color: #242830;
      width: fit-content;
      border-radius: 50%;
      align-items: center;
      display: flex;
      margin-left: auto;
      cursor: pointer;
      &__img {
        color: var(--bonadocsStatusBgLight);
        opacity: 1;
        padding: 0.8rem;
        cursor: pointer;
      }
    }
  }
  &__container {
    background-color: var(--bonadocsEditorBackground);
    border-radius: 8px;
    padding: 2.4rem;
    overflow: auto;
    display: grid;
    // max-width: 40rem;
    color: var(--bonadocsTextWhite);
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &__wrapper {
      justify-self: flex-end;
      color: var(--bonadocsTextWhite);
      &__inner {
        height: max-content;
        &__input {
          
      
          width: 100%;
        }
        &__button {
          width: 18%;
          border-radius: 0 0.4rem 0.4rem 0rem ;
          padding: 1.3rem;
          height: 100% ;
        }
      }
    }
     &__loader {
        
        display: flex;
        justify-content: center;
        align-items: center;
      }
    &__dropdown {
      width: 100% !important;
      border-radius: 0.4rem !important;
      margin-top: 1rem !important;
    }
    &__title {
      //justify-self: center;
      font-size: 1.6rem;
      color: var(--bonadocsTextWhite);
      font-weight: 600;
    }
    &__text {
      margin-top: 2rem;
      //justify-self: center;
      color: var(--bonadocsTextWhite);
      line-height: 2.4rem;
      font-size: 1.3rem;
    }
    &__button {
      cursor: pointer;
      font-size: 1.3rem;
      margin-bottom: 1.4rem;
      margin-top: 2.4rem;
      padding: 0.8rem;
    }
  }
  &__description {
    margin-top: 1.3rem;
  }
  &__title {
    font-size: 1.6rem;
    font-weight: 600;
  }
  &__close {
    margin-left: auto;
    width: 3rem;
    color: var(--bonadocsTextWhite);
    height: 3rem;
    padding: 0.4rem;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--bonadocsEditorBackground);
    cursor: pointer;
    margin-bottom: 1.6rem;
  }
}

.bonadocs {
  &__editor {
    &__projects {
      background-color: var(--bonadocsLoginBg);
      height: inherit;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 1.6rem;

      &__members {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
        padding: 2.4rem 0 1.6rem 0;
        border-bottom: 1px solid var(--bonadocsProtocolBorder);
        @media screen and (max-width: 1500px) {
          grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
        }
        &__item {
          text-transform: uppercase;
          color: var(--bonadocsSecondaryText);
          font-size: 1.4rem;
        }
        &__details {
          font-size: 1.3rem;
        }
      }
      &__billings {
        &__wrapper {
          display: flex;
          gap: 2.3rem;
        }
        &__card {
          border: 1px solid var(--bonadocsProtocolBorder);
          background-color: var(--bonadocsNetworkBg);
          width: min-content;
          min-width: 32rem;
          margin-top: 2.4rem;
          padding: 2.4rem;
          border-radius: 0.8rem;
          min-height: 36.8rem;
          &__wrapper {
            width: 100%;
          }
          &__button {
            width: inherit;
            padding: 1.2rem 1.6rem !important;
            margin: 1rem 0;
          }
          &__price {
            font-size: 2rem;
            color: var(--bonadocsTextWhite);
            margin: 1rem 0 1.4rem 0;
          }
          &__feature {
            &__title {
              font-size: 1.6rem;
              color: var(--bonadocsSecondaryText);
            }
            &__descr {
              font-size: 1.2rem;
            }
          }
        }
      }

      &__inner {
        // padding: 0 12rem;
        width: 100%;
        display: grid;
        &__control {
          display: flex;
          width: 100%;
          margin-bottom: 2.4rem;
          &__item {
            border-bottom: 1px solid var(--bonadocsProtocolBorder);
            padding: 1.6rem;
            font-size: 1.3rem;
            cursor: pointer;
            display: flex;
            &__members {
              display: flex;
              background-color: var(--bonadocsNetworkBg);
              padding: 0 0.8rem;
              border-radius: 1.2rem;
              margin-left: 0.8rem;

              &__active {
                color: var(--bonadocsTextWhite);
                background-color: var(--bonadocsInfoBg);
              }
            }
            &__active {
              color: var(--bonadocsProtocolBlue);
              border-bottom: 2px solid var(--bonadocsProtocolBlue);
            }
          }
          &__free {
            width: 100%;
            border-bottom: 1px solid var(--bonadocsProtocolBorder);
          }
        }
        &__header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 7rem;
          // padding: 3rem 0;
          &__button {
            display: flex;
            align-items: center;
            img {
              margin-right: 0.8rem;
            }
          }
          &__left {
            align-self: center;
            &__title {
              color: var(--bonadocsTextWhite);
              font-size: 1.4rem;
              padding-left: 5rem;
            }
            &__description {
              font-size: 1.6rem;
              padding-top: 0.8rem;
            }
          }
          &__right {
            display: flex;
            gap: 1rem;
            &__button {
              display: flex;
              height: fit-content;
              padding: 0.8rem 1.6rem !important;
              align-items: center;
              gap: 0.4rem;
              img {
                height: 2.4rem;
                width: 2.4rem;
              }
            }
          }
        }
        &__info {
          margin-top: 2.4rem;
          &__title {
            color: var(--bonadocsTextWhite);
            font-size: 2rem;
            line-height: 3.2rem;
            letter-spacing: 0.02rem;
          }
          &__desc {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
        &__list {
          display: grid;
          gap: 1.6rem;
          grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
          grid-template-rows: repeat(auto-fill, minmax(15rem, 1fr));

          max-height: 85vh;

          overflow-y: auto;

          &__projects {
            grid-template-rows: repeat(auto-fill, minmax(12rem, 1fr));
          }
          &__item {
            border-radius: 0.8rem;
            padding: 1.6rem;
            border: 1px solid var(--bonadocsProtocolBorder);
            cursor: pointer;
            height: min-content;
            min-height: 12rem;
            &__descr {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0.4rem 0;
            }

            &:hover {
              background-color: var(--bonadocsBackground);
            }
            &__wrapper {
              &:hover {
                background-color: var(--bonadocsBackground);
              }
            }
            &__icon {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 0.8rem;
              &__big {
                width: 4rem;
                height: 4rem;
                margin-bottom: 1.6rem;
              }
            }
            &__info {
              width: 90%;
              &__title {
                font-size: 1.4rem;
                color: var(--bonadocsTextWhite);
                font-weight: 600;
              }
              &__description {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                font-size: 1.4rem;
                margin-top: 0.4rem;
              }
              &__team {
                margin-top: 0.4rem;
                display: flex;
                &__members {
                  font-size: 1.4rem;
                  margin-left: 0.4rem;
                }
              }
            }
            &__inner {
              display: flex;
            }
            &__header {
              display: flex;
            }
          }
        }
      }
      &__creation {
        &__selection {
          background-color: var(--bonadocsNetworkBg);
          height: min-content;
          max-height: 65%;
          margin-top: 0.8rem;
          border-radius: 0.8rem;
          padding: 0.8rem;

          &__instances {
            background-color: var(--bonadocsNetworkBg);
            height: min-content;

            margin-top: 0.8rem;
            border-radius: 0.8rem;
            padding: 0.8rem;
            width: 100%;
            &__list {
              overflow-y: auto;
              height: 30rem;
            }
          }
          &__list {
            overflow-y: auto;
            height: 30rem;
          }
          &__item {
            display: flex;
            gap: 0.8rem;
            cursor: pointer;
            padding: 1.6rem 0.8rem;
            border-radius: 0.8rem 0.8rem 0 0;

            // margin: 0.8rem 0;
            &__active {
              background-color: var(--bonadocsEditorBackground);
            }
            &__deets {
              background-color: var(--bonadocsEditorBackground);
              border-radius: 0 0 0.8rem 0.8rem;
              padding: 0.8rem 1.6rem 1.6rem 4rem;
              display: grid;
              margin-bottom: 0.8rem;
              &__wrapper {
                margin-top: 1.2rem;
                margin-left: auto;
                display: flex;
                gap: 1rem;
              }
              &__header {
                color: var(--bonadocsTextWhite);
                font-size: 1.3rem;
                margin-top: 0.8rem;
              }
              &__button {
                margin-left: auto;
                margin-top: 0.8rem;
              }
              &__delete {
                color: var(--bonadocsTextCritical);
                margin-top: 0.5rem;
                cursor: pointer;
                gap: 1rem;
              }
            }
            &__image {
              width: 2.4rem;
              height: 2.4rem;
            }
            &__name {
              font-size: 1.3rem;
              color: var(--bonadocsTextWhite);
            }
            &__icon {
              margin-left: auto;
              &__active {
                transform: rotate(180deg);
              }
            }
          }
        }
        &__add {
          display: flex;
          margin-left: auto;
          align-items: center;
          font-size: 1.4rem;
          cursor: pointer;
          margin-top: 1.2rem;
          margin-bottom: 1rem;
          &__icon {
            color: var(--bonadocsProtocolBlue);
          }

          &__title {
            color: var(--bonadocsProtocolBlue);
            cursor: pointer;
            font-size: 1.4rem;
          }
          &__cancel {
            margin-right: 1rem;
          }
        }
      }
      &__action {
        display: flex;
        gap: 12rem;
        margin-top: 4.8rem;
        &__contract {
          &__list {
            max-height: 90%;
            overflow-y: auto;
          }
        }
        &__wrapper {
          width: 30%;
          max-width: 30vw;
        }
        &__select {
          border: 1px solid var(--bonadocsEditorBorder);
          padding: 1rem;
          color: var(--bonadocsTextWhite);
          margin-top: 0.4rem;
          border-radius: 0.4rem;
          cursor: pointer;
          max-width: 30vw;
          &__name {
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.4rem;
            letter-spacing: 0.013rem;
            margin-top: 1rem;
            color: var(--bonadocsSecondaryText);
            cursor: pointer;
            &__large {
              line-height: 2.4rem;
              letter-spacing: 0.016rem;
              font-size: 1.6rem;
              font-weight: 700;
              color: var(--bonadocsSecondaryText);
              margin-top: 3.2rem;
              margin-bottom: 1rem;
            }
          }
          &__input {
            width: 100%;
          }
          &__inner {
            height: 2.4rem;
            display: flex;

            &__network {
              display: flex;
              width: 90%;
              gap: 0.8rem;
              overflow-x: auto;
              overflow-y: hidden;
              /* height: 100px; */
              width: 90%;

              scrollbar-width: none;
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE and Edge */
              &::-webkit-scrollbar {
                display: none;
              }
              &__placeholder {
                color: var(--bonaodocsPlaceholder);
                font-size: 1.2rem;
                align-self: center;
              }
              &__item {
                display: flex;
                border-radius: 4px;
                background-color: var(--bonadocsNetworkBg);
                height: 100%;
                padding: 0.4rem 0.8rem;
                align-items: center;
                img {
                }
                &__name {
                  margin: 0 0.8rem 0 0.4rem;
                  color: var(--bonadocsTextWhite);
                  width: max-content;
                  font-size: 1.2rem;
                }
              }
              &__element {
                display: flex;
                background-color: var(--bonadocsNetworkBg);
                padding: 0.4rem 0.8rem;
                align-items: center;

                &__name {
                  font-size: 1.2rem;
                  color: var(--bonadocsTextWhite);
                  margin: 0 0.8rem 0 0.4rem;
                  width: max-content;
                }
                &__image {
                  width: 1.2rem;
                  height: 1.2rem;
                }
              }
            }
            img {
              height: 100%;
              margin-left: auto;
              padding: 0.2rem 0;
            }
          }
        }
        &__project,
        &__contract {
          &__options {
            font-size: 1.4rem;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            &__add {
              color: var(--bonadocsProtocolBlue);
            }
            &__delete {
              color: var(--bonadocsTextCritical);
            }
          }
          // width: 30%;
          color: var(--bonadocsSecondaryText);
          &__title {
            line-height: 2.4rem;
            letter-spacing: 0.016rem;
            font-size: 1.6rem;
            font-weight: 700;
            color: var(--bonadocsSecondaryText);
            margin-bottom: 3.2rem;
          }
          &__form {
            width: 100%;
            &__item {
              width: inherit;
              margin-bottom: 1.6rem;
              &__name {
                font-size: 1.3rem;
                font-weight: 500;
                line-height: 2.4rem;
                letter-spacing: 0.013rem;
                color: var(--bonadocsSecondaryText);
              }
              &__input {
                width: inherit;
              }
            }
          }
          &__pagination {
            margin-top: auto;
            display: flex;
            justify-content: center;
            &__numbers {
              max-width: 50%;
              display: flex;
              overflow-x: scroll;
            }
            &__wrapper {
              display: ruby-text;
              text-align: center;
              display: flex;
              text-align: center;
              justify-content: center;
            }
            &__start {
              transform: rotate(90deg);
              cursor: pointer;
              align-self: center;
              margin-top: 50%;
            }
            &__end {
              transform: rotate(270deg);
              cursor: pointer;
              align-self: center;
              margin-top: 50%;
            }
            &__item {
              padding: 0.8rem 1.2rem;
              border-radius: 10rem;
              background-color: var(--bonadocsProtocolBackground);
              color: var(--bonadocsTextWhite);
              font-size: 1.3rem;
              margin-right: 0.8rem;
              width: max-content;
              margin: 0 auto;
              cursor: pointer;
              display: flex;
              &__active {
                background-color: var(--bonadocsProtocolBlue);
                color: var(--bonadocsProtocolSecondaryBackground);
              }
            }
          }
        }
        &__steps {
          &__gap {
            border-left: 1px solid var(--bonadocsEditorBorder);
            height: 6.2rem;
            margin: 1.8rem 0 1.8rem 1.6rem;
          }
          &__item {
            display: flex;
            gap: 0.8rem;
            cursor: pointer;
            &__icon {
              width: 3.2rem;
              height: 3.2rem;
              font-size: 1.2rem;
              display: flex;
              padding: 0.8rem;
              border-radius: 50%;
              background-color: var(--bonadocsStatusBgLight);
              h3 {
                color: var(--bonadocsEditorBackground);
                margin: 0 auto;
              }
            }
            &__text {
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 700;
              line-height: 2.4rem;
              letter-spacing: 0.014rem;
              color: var(--bonadocsSecondaryText);
            }
          }
        }
        &__button {
          width: 100%;
          padding: 1.2rem 1.6rem !important;
          margin-top: 3.2rem;
        }
      }
    }
  }
}

.bonadocs {
  &__editor {
    &__variables {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: inherit;

      &__table {
        margin: 1.6rem;
        height: auto;
        display: flex;
        /* flex: 1 1; */
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        overflow-y: auto;
        &__placeholder {
        padding-top: 20%;
        }

        &__items {
          flex-grow: 1;
          height: auto;
          overflow-y: auto;
        }
        &__header {
          display: grid;
          grid-template-columns: 1.93fr 1fr 1fr;
          padding: 1.6rem;
          background-color: var(--bonadocsBackground);

          h1 {
            color: var(--bonadocsTextWhite);
            font-size: 1.4rem;
          }
          &__menu {
            display: flex;
          }
        }
        &__item {
          display: grid;
          grid-template-columns: 1.93fr 1fr 1fr;
          padding: 1.6rem;
          border-bottom: 1px solid var(--bonadocsEditorBorder);

          &__menu {
            display: flex;
          }
          h3 {
            font-size: 1.3rem;
          }
          &__popover {
            position: absolute;
            z-index: 4;
            &__item {
              display: grid;
              border: 1px solid var(--bonadocsEditorBorder);
              border-radius: 0.8rem;
              padding: 1.6rem;
              width: 19.1rem;
              background-color: var(--bonadocsEditorBackground);
              font-size: 1.6rem;
              &__edit {
                width: 100%;
                padding: 0.8rem;
                color: var(--bonadocsTextWhite);
                cursor: pointer;
              }
              &__edit:hover {
                background-color: var(--bonadocsBackground);
              }
              &__delete {
                width: 100%;
                padding: 0.8rem;
                color: var(--bonadocsTextCritical);
                cursor: pointer;
              }
              &__delete:hover {
                background-color: var(--bonadocsBackground);
              }
            }
          }
        }
      }
      &__header {
        display: inherit;
        width: 100%;
        padding: 0.8rem;
        height: 4rem;
        align-items: center;
        border-bottom: 1px solid var(--bonadocsEditorBorder);
        &__title {
          color: var(--bonadocsTextWhite);
          font-size: 1.4rem;
        }
        &__add {
          margin-left: auto;
          display: inherit;
          cursor: pointer;
          align-items: center;
          color: var(--bonadocsButtonBg);
          font-size: 1.4rem;
        }
      }
    }
  }
}



